var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.wallet.connected
    ? _c(
        "button",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.sign.apply(null, arguments)
            },
          },
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }