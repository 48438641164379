<template>
	<button @click.stop="sign" v-if="$store.state.wallet.connected"><slot></slot></button>
</template>

<script>
import Auth from "@/js/api/auth";
import {useWallet} from "@alphabatem/vue2-wallet-adapter";

export default {
	name: "PhantomSign",
	methods: {
		sign: async function (e) {
			e.preventDefault();

			const nonce = await this.nonce().catch(e => {
				this.$toastr.e("Unable to authenticate, please try again", "Authentication failed");
				return;
			});

			this._sign(nonce)
		},

		_sign(nonce) {
			if (this.$store.state.wallet.isWeb3)
				return this.signWithNonceNew(nonce)

			return this.signWeb2(nonce)
		},

		/**
		 * Verify via web2 login
		 * @param nonce
		 */
		signWeb2(nonce) {
			const enc = new TextEncoder()
			const data = enc.encode(this.$store.state.wallet.jwt_token)

			const d = []
			for (let i = 0; i < data.length;i++) {
				d.push(data[i])
			}

			this.$emit("sign", {
				publicKey: this.$store.state.wallet.address,
				signature: {
					data: d,
					type: "JWT"
				},
			});
		},

		signWithNonceNew(nonce) {
			useWallet().signMessage(new TextEncoder().encode(`I am signing my game-authentication nonce: ${this.$store.state.wallet.address}:${nonce}`), "utf8").then(r => {
				console.log("Signature:", r);
				this.$emit("sign", {
					publicKey: this.$store.state.wallet.address,
					signature: r,
				});
			}).catch(e => {
				console.error(e);
				this.$toastr.e("Unable to sign message", "Wallet error");
			});
		},

		signWithNonce(nonce) {
			window.solana.signMessage(new TextEncoder().encode(`I am signing my game-authentication nonce: ${this.$store.state.wallet.address}:${nonce}`), "utf8").then(r => {
				console.log("Signature:", r);
				this.$emit("sign", r);
			}).catch(e => {
				console.error(e);
				this.$toastr.e("Unable to sign message", "Wallet error");
			});
		},

		nonce: async function() {
			const n = await Auth.nonce(this.$store.state.wallet.address)
			return n.data.nonce
		}
	}
}
</script>

<style scoped>

</style>
